
























































import { promiseHelper } from '@/helpers/promise-helper'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { saleViewModel } from '../viewmodels/market-viewmodel'

import { dashboardViewModel } from '@/modules/dashboard/viewmodels/dashboard-viewmodel'

@Observer
@Component({
  components: {},
})
export default class ConfirmSaleDialog extends Vue {
  vm = saleViewModel

  dialog = false
  resolve?: (any) => void

  async open() {
    this.dialog = true
    await promiseHelper.delay(100)
    return new Promise((r) => (this.resolve = r))
  }
  async cancel(success = false) {
    this.resolve && this.resolve(success)
    this.dialog = false
    await promiseHelper.delay(100)
  }

  async confirm() {
    const res: any = await this.vm.createOrder()
    if (res === 'success' || res === 'cancel-error') {
      dashboardViewModel.loadInvestments()
      this.cancel(res === 'success')
    }
  }
}
